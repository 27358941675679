import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				SkyHigh Marketing Agency
			</title>
			<meta name={"description"} content={"Eleve su marca, alcance nuevas alturas"} />
			<meta property={"og:title"} content={"SkyHigh Marketing Agency"} />
			<meta property={"og:description"} content={"Eleve su marca, alcance nuevas alturas"} />
			<meta property={"og:image"} content={"https://harmonichavn.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://harmonichavn.com/img/5347474357.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://harmonichavn.com/img/5347474357.png"} />
			<meta name={"msapplication-TileImage"} content={"https://harmonichavn.com/img/5347474357.png"} />
			<meta name={"msapplication-TileColor"} content={"https://harmonichavn.com/img/5347474357.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				align-items="flex-start"
				lg-align-items="center"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				justify-content="flex-start"
				display="flex"
				width="50%"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--darkL2"
						font="--headline1"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Descubra nuestra amplia oferta
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--grey" font="--lead" lg-text-align="center">
				En SkyHigh Marketing Agency, ofrecemos un conjunto completo de servicios diseñados para elevar su marca e impulsar su negocio. Nuestra experiencia abarca varios ámbitos del marketing, lo que garantiza que todas sus necesidades queden cubiertas bajo un mismo techo. Desde la planificación estratégica hasta la ejecución creativa, somos sus socios en el éxito.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 100% 0px"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						object-fit="cover"
						display="block"
						width="100%"
						right={0}
						src="https://harmonichavn.com/img/4.jpg"
						position="absolute"
						top="auto"
						left={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-8" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="24px 24px 24px 24px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					width="100%"
				>
					<Image
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://harmonichavn.com/img/5.jpg"
						object-fit="cover"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-order="1"
				justify-content="flex-start"
				align-items="flex-start"
				padding="24px 24px 24px 24px"
			>
				<Box>
					<Text
						margin="0px 0px 36px 0px"
						color="--light"
						font="--headline2"
						lg-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Eleve su marca con SkyHigh
					</Text>
				</Box>
				<Text margin="0px 0px 0px 0px" color="--light" font="--lead" lg-text-align="center">
					Nuestro objetivo es ayudarle a destacar en un mercado saturado. Combinamos los principios del marketing tradicional con estrategias digitales innovadoras para crear un enfoque holístico adaptado a sus necesidades específicas. Tanto si se trata de una nueva empresa como de una empresa consolidada, nuestros servicios están diseñados para alinearse con sus objetivos empresariales e impulsar resultados cuantificables.
					<br />
					<br />
					Nuestro equipo de expertos se compromete a ofrecer un servicio de primera clase y a superar sus expectativas. Entendemos la importancia de una base de marketing sólida y estamos aquí para apoyarle en cada paso del camino.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline2" md-font="--headline2" margin="20px 0 0 0">
				Dé el siguiente paso
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				¿Está listo para transformar su estrategia de marketing? Póngase en contacto con SkyHigh hoy mismo y experimente la diferencia que puede marcar nuestra experiencia. Vamos a trabajar juntos para elevar su marca y lograr resultados notables.
			</Text>
			<Button
				font="--lead"
				margin="20px"
				type="link"
				text-decoration-line="initial"
				href="/contact"
			>
				Transformar visiones en realidades
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652f9cc5dbe26f0020fd00a7"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});